import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class PageTemplate extends React.Component {
  render() {
    const entry = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout>
        <SEO
          title={entry.frontmatter.title}
          description={entry.frontmatter.description || entry.excerpt}
        />
          <aside className={entry.parent.name} />
          <article className={"page " + entry.parent.name} dangerouslySetInnerHTML={{ __html: entry.html }} />
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
      parent {
        ... on File {
          name
        }
      }
    }
  }
`
